<template>
  <div class="box">
    <div class="jbxx">
      <div class="jbtop">
        <div class="icon"></div>
        <div class="tit">每日签到奖励配置</div>
      </div>
      <el-form ref="form" :model="form" label-width="200px">
        <el-row>
          <el-col :span="8" style="display: flex;">
            <el-form-item label="签到1次奖励">
              <el-input v-model="form.REWARD"><template slot="append">积分</template></el-input>

            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin-top: 20px;">
          <el-col :span="8">
            <el-form-item label="活动规则">
              <el-input type="textarea" :autosize="{minRows: 10}" v-model="form.RULE" name="" id=""></el-input>
            </el-form-item>
          </el-col>

        </el-row>
        <el-row style="margin-top: 20px;">
          <el-col :span="8">
            <el-form-item label="活动时间">
              <el-date-picker @change="change" v-model="valuechange" type="daterange" start-placeholder="开始日期"
                end-placeholder="结束日期" />
            </el-form-item>

          </el-col>

        </el-row>

      </el-form>
      <div class="butt" style="text-align: center;">
        <!-- <el-button style="width: 140px;height: 50px;" @click="$router.push('/auditBusinessone')">取消</el-button> -->
        <el-button size="medium" type="primary" @click="onSubmit">保存</el-button>
      </div>
    </div>

  </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
export default {
  components: {   //界面组件引用
    quillEditor
  },
  data() {
    return {
      form: {
        REWARD: '',
        RULE: '',
        STARTDATE: '',
        ENDDATE: ''
      },
      showDialog: false,

      value: '',
      valuechange: ''

    };
  },
  mounted() {
    var that = this;
    that.$http.post("/setup/queryOne", {
      id: 12,
      setupType: 7,
    }).then(function (response) {
      console.log(response.data.data);
      if (response.data.code == 200) {
        that.form = response.data.data.setupContentJson
        that.valuechange = [response.data.data.setupContentJson.STARTDATE
          , response.data.data.setupContentJson.ENDDATE]


      }
    });
  },
  methods: {
    handleSuccess(response, file, fileList) {
      console.log(response.data.url);
    },
    onSubmit() {
      // this.$router.push('/staff')
    },
    add() {
      this.showDialog = true
    },
    change() {
      console.log(this.valuechange);
      this.form.STARTDATE = this.newDate(this.valuechange[0])
      this.form.ENDDATE = this.newDate(this.valuechange[1])
    },
    newDate(time) {
      var date = new Date(time)
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? '0' + m : m
      var d = date.getDate()
      d = d < 10 ? '0' + d : d
      var h = date.getHours()
      h = h < 10 ? '0' + h : h
      var minute = date.getMinutes()
      minute = minute < 10 ? '0' + minute : minute
      var s = date.getSeconds()
      s = s < 10 ? '0' + s : s
      return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + s
    },
    onSubmit() {
      var that = this;
      that.$http.post("/setup/update", {
        id: 12,
        setupType: 7,
        setupContent: that.form
      }).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          that.$message({
            type: 'success',
            message: '配置成功'
          });

        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.box {
  background-color: #fff;
  padding-top: 20px;

  .jbxx {
    padding: 10px;

    .jbtop {
      height: 56px;
      background-color: #eef1f6;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      padding-left: 20px;
      margin-left: 10px;
      margin-bottom: 40px;

      .icon {
        width: 6px;
        height: 19px;
        border-radius: 10px;
        background-color: #f77f1f;
        margin-right: 10px;
      }

      .tit {
        font-weight: 700;
      }


    }

    .edi {
      margin-left: 20px;
      height: 450px;
      display: flex;

      .editor {
        width: 817px;
        height: 372px;
        margin-top: 20px;
      }
    }
  }

  .butt {
    // display: flex;
    // justify-content: space-between;
    padding: 50px 0px;
  }

  .tab {
    padding: 20px;
  }

  .dia {
    .diafour {
      display: flex;
      justify-content: space-between;
      padding: 20px 230px;
    }
  }
}
</style>